<template>
  <v-dialog v-model="dialog" transition="dialog-bottom-transition" persistent width="600" @click:outside="$emit('close')">
    <v-card class="px-4 py-6">
      <span class="headline"><v-icon class="mb-1 mr-1">mdi-server</v-icon> Nova Conexão</span>
      <v-form ref="form" v-model="valid" class="px-4" lazy-validation>
        <v-row>
          <v-col cols="3">
            <v-switch v-model="server.pos" label="PDV" color="primary" spacing="2" />
          </v-col>
          <v-col cols="9">
            <v-select
              v-if="!server.pos"
              v-model="server.integrator"
              :items="server.erpServers"
              label="Integrator(s)"
              :rules="integratorRules"
              class="pt-2"
              return-object
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-text-field v-model="server.connection.database" :rules="dbRules" label="Database*" outlined required />
        </v-row>
        <v-row>
          <v-text-field v-model="server.connection.charset" :rules="charsetRules" label="Charset" outlined required />
        </v-row>
        <v-row>
          <v-text-field v-model="server.connection.host" class="mr-4" :rules="hostRules" label="Host*" outlined required />
          <v-text-field v-model="server.connection.port" :rules="portRules" type="number" label="Porta*" outlined required />
        </v-row>
        <v-row>
          <v-text-field v-model="server.connection.user" class="mr-4" :rules="userRules" label="Usuário*" outlined required />
          <v-text-field
            v-model="server.connection.password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            :rules="passRules"
            name="input-10-1"
            label="Senha"
            counter
            autocomplete="off"
            outlined
            required
            @click:append="show = !show"
          ></v-text-field>
        </v-row>
      </v-form>
      <btn-save-cancel :save-btn-disable="!valid" :btn-size="200" props-class="ma-0 px-1 py-0" @onSave="saveManager" @onCancel="$emit('close')" />
      <change-dialog :dialog.sync="changesDialog" :message.sync="changesMessage" @proceed="saveServer" />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    BtnSaveCancel: () => import('@/components/base/BtnSaveCancel'),
    ChangeDialog: () => import('@/components/base/ChangeDialog')
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => ({})
    },
    server: {
      type: Object,
      default: () => ({})
    },
    isDeploying: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    show: false,
    valid: false,
    integratorRules: [v => !!v || 'Integrator é obrigatório'],
    dbRules: [v => !!v || 'Database é obrigatório'],
    charsetRules: [v => !!v || 'Charset é obrigatório'],
    hostRules: [v => !!v || 'Host é obrigatório'],
    portRules: [v => !!v || 'Porta é obrigatória'],
    userRules: [v => !!v || 'User é obrigatório'],
    passRules: [v => !!v || 'Password é obrigatório'],
    changesDialog: false,
    changesMessage: ''
  }),
  methods: {
    saveServer() {
      if (!this.$refs.form.validate()) return this.$snackbar({ message: 'Erro ao validar campos', snackbarColor: '#F44336' })
      this.$setLogMessage(this.changesMessage)
      this.$emit('update:server', this.server)
      this.$emit('save')
    },
    saveManager() {
      if (this.isDeploying) return this.saveServer()
      this.changesDialog = true
    }
  }
}
</script>
